// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.titleDistri{
    color: rgb(102, 102, 102);
    font-weight: 500;
}

.containerDistribuidor {
    padding-top: 1rem;
    flex: 1 1; 
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    justify-content: center; 
    align-items: center;
    
}

.image-feature-dis{
    width: 250px;
}


.containerDistri{
    display: flex;
}
`, "",{"version":3,"sources":["webpack://./src/components/Distribuidores/Distribuidores.css"],"names":[],"mappings":";AACA;IACI,yBAAyB;IACzB,gBAAgB;AACpB;;AAEA;IACI,iBAAiB;IACjB,SAAO;IACP,yBAAyB;IACzB,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,mBAAmB;;AAEvB;;AAEA;IACI,YAAY;AAChB;;;AAGA;IACI,aAAa;AACjB","sourcesContent":["\n.titleDistri{\n    color: rgb(102, 102, 102);\n    font-weight: 500;\n}\n\n.containerDistribuidor {\n    padding-top: 1rem;\n    flex: 1; \n    background-color: #ffffff;\n    display: flex;\n    flex-direction: column;\n    justify-content: center; \n    align-items: center;\n    \n}\n\n.image-feature-dis{\n    width: 250px;\n}\n\n\n.containerDistri{\n    display: flex;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
